import React from 'react'

function Footer(){
  return (
    <div className='footer'>
    <p className='footer-text'>&copy; 2022 ROVA Coaching</p>
    </div>
  )
}

export default Footer